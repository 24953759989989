@font-face {
  font-family: "db_heaventbold";
  src: url("./assets/fonts/db_heavent_bd_v3.2-webfont.eot");
  src: url("./assets/fonts/db_heavent_bd_v3.2-webfont.eot?#iefix")
      format("embedded-opentype"),
    url("./assets/fonts/db_heavent_bd_v3.2-webfont.woff2") format("woff2"),
    url("./assets/fonts/db_heavent_bd_v3.2-webfont.woff") format("woff"),
    url("./assets/fonts/db_heavent_bd_v3.2-webfont.ttf") format("truetype"),
    url("./assets/fonts/db_heavent_bd_v3.2-webfont.svg#db_heaventbold")
      format("svg");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "db_heaventmed";
  src: url("./assets/fonts/db_heavent_med_v3.2-webfont.eot");
  src: url("./assets/fonts/db_heavent_med_v3.2-webfont.eot?#iefix")
      format("embedded-opentype"),
    url("./assets/fonts/db_heavent_med_v3.2-webfont.woff2") format("woff2"),
    url("./assets/fonts/db_heavent_med_v3.2-webfont.woff") format("woff"),
    url("./assets/fonts/db_heavent_med_v3.2-webfont.ttf") format("truetype"),
    url("./assets/fonts/db_heavent_med_v3.2-webfont.svg#db_heaventmed")
      format("svg");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "db_heaventthin";
  src: url("./assets/fonts/db_heavent_thin_v3.2-webfont.eot");
  src: url("./assets/fonts/db_heavent_thin_v3.2-webfont.eot?#iefix")
      format("embedded-opentype"),
    url("./assets/fonts/db_heavent_thin_v3.2-webfont.woff2") format("woff2"),
    url("./assets/fonts/db_heavent_thin_v3.2-webfont.woff") format("woff"),
    url("./assets/fonts/db_heavent_thin_v3.2-webfont.ttf") format("truetype"),
    url("./assets/fonts/db_heavent_thin_v3.2-webfont.svg#db_heaventthin")
      format("svg");
  font-weight: normal;
  font-style: normal;
}

$fill-bg-content: #f1f5f6;
$fill-blue: #007bfe;
$fill-blue-header: #4b75c0;
$fill-blue-table: #037afb;
$fill-dark-grey: #252526;
$fill-light-grey: #bdbebf;
$fill-grey-font: #939ca9;
$fill-red: #d80012;
$fill-white: #fff;
$fill-black: #000;

$shadow: 3px 3px 10px rgba(0, 0, 0, 0.08);

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  outline: none;
}

hr {
  border: 1px solid $fill-light-grey;
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
  font-weight: normal;
  font-family: "db_heaventmed";
}

h1,
h2,
h3,
h4,
h5,
h6 {
  line-height: 0.6;
}

ul {
  list-style: none;
}

input[type="text"],
input[type="password"] {
  border-radius: 4px;
  padding: 12px 10px;
  // font-family: "db_heaventmed";
  font-size: 1rem;
  border: none;
}

img {
  max-width: 100%;
  max-height: 100%;
}

button {
  border: none;
  margin: 0 10px;
  background-color: transparent;
  font-size: 1.4rem;

  &:focus {
    outline: none !important;
    outline-offset: none !important;
  }
}

// Core ----------------------------------------------------------//
body {
  background-color: #f1f5f6;
  // color: $fill-grey-font;
  font-size: 1.6rem;
  font-family: "db_heaventmed", sans-serif;
}

#root {
  .btn-back {
    position: absolute;
    top: 20px;
    left: 20px;
  }
}

.loading {
  &.container {
    .container-top {
      height: 100vh;

      h3 {
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        margin: auto;
        width: 100px;
        height: 80px;
      }
    }
  }
}

.mg-t-5 {
  margin-top: 5px !important;
}

.mg-t-10 {
  margin-top: 10px !important;
}

.mg-t-15 {
  margin-top: 15px !important;
}

.mg-t-20 {
  margin-top: 20px !important;
}

.mg-t-30 {
  margin-top: 30px !important;
}

.mg-t-40 {
  margin-top: 40px !important;
}

.mg-b-5 {
  margin-bottom: 5px !important;
}

.mg-b-10 {
  margin-bottom: 10px !important;
}

.mg-b-15 {
  margin-top: 15px !important;
}

.mg-b-20 {
  margin-bottom: 20px !important;
}

.mg-b-30 {
  margin-bottom: 30px !important;
}

.mg-b-40 {
  margin-bottom: 40px !important;
}

.font-white {
  color: $fill-white;
}

.font-red {
  color: $fill-red;
}

.font-dark-grey {
  color: $fill-dark-grey;
}

.font-blue {
  color: $fill-blue;
}

.btn-back {
  background-color: rgba(255, 255, 255, 0.18);
  border-radius: 30px;
  padding: 11px;
  width: 42px;
  height: 42px;

  img {
    width: 18px;
    height: auto;
    margin-left: -2px;
  }
}

.btn-round {
  // min-width: 180px;
  background-color: $fill-white;
  box-shadow: $shadow;
  width: auto;
  height: auto;
  padding: 18px 16%;
  margin: auto;
  border-radius: 4px;
  font-size: 1.2rem;

  &._blue {
    background-color: #007bfe;
    color: #fff;
  }

  &._grey {
    background-color: #878787;
    color: #000;
  }
}

.loading-overlay {
  width: 100%;
  height: 100%;
  position: absolute;
  background-color: rgba(0, 0, 0, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
}

// Login Page --------------------------//
.login-page {
  background-color: #878b8e;
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;

  .login-box {
    text-align: center;
    width: 60%;
    margin: auto;
    input {
      width: 100%;
      margin-bottom: 10px;
    }
    button {
      margin-top: 10px;
      width: 100%;
    }
  }

  .login-logo {
    & > * {
      color: $fill-white;
    }
    img {
      max-width: 160px;
      margin: 30px 0;
    }
  }

  .login-form {
    margin: 30px 0;
    padding: 0 15%;
  }
}

// Table ----------------------------//
table {
  width: 100%;
  border: 1px solid #e2e4e4;
  border-collapse: collapse;

  th {
    background-color: $fill-blue-table;
    color: $fill-white;
    font-weight: normal;
    cursor: pointer;
  }

  th,
  td {
    padding: 10px;
    font-size: 1.2rem;
    text-align: center;
    border: 1px solid #e2e4e4;
  }

  tr {
    border-top: 1px solid #e2e4e4;
    &:nth-child(odd) {
      background-color: #f8fcfd;
    }
  }
}

.table-ecp-detail {
  tr {
    th:first-child {
      width: 40px !important;
    }
  }
}

.coupon_status_selected {
  border: none;
  font-family: "db_heaventmed", sans-serif;
  font-size: 1rem;
  option {
    font-family: "db_heaventmed", sans-serif;
  }
}

.hideCheckbox {
  input[type="checkbox"] {
    display: none;
  }
}

.coupon-expiring {
  color: $fill-red;
}

.pagination {
  display: flex;
  justify-content: space-between;
  span {
    font-size: 1rem;
    color: $fill-grey-font;
  }
  button {
    font-size: 1rem;
  }

  select {
    border: none;
    background-color: #f1f5f6;
  }

  .pagination-right,
  .pagination-left {
    display: flex;
    align-items: center;
  }
}

// Home ----------------------------//
.home-page {
  .header {
    height: 8vh;
    display: flex;

    .left-side {
      background-color: $fill-blue-header;
      justify-content: space-between;
      display: flex;

      .logo {
        display: flex;
        font-size: 1.8rem;
        color: $fill-white;
        align-items: center;
        padding: 12px 0 12px 20px;
        img {
          width: 40px;
          margin-right: 10px;
        }
      }
    }

    .right-side {
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
      align-items: center;
      background-color: $fill-white;
      padding: 20px;
    }

    .logout {
      padding: 0 20px;
      color: $fill-blue;
      border-right: 1px solid $fill-light-grey;
    }

    .username {
      padding: 0 10px 0 20px;
      color: $fill-dark-grey;
    }

    .avatar {
      align-items: center;
      display: flex;
      width: 40px;
      border-radius: 100%;
      overflow: hidden;
    }
  }

  .left-side {
    flex: 1.5;
    background-color: $fill-dark-grey;
  }
  .right-side {
    flex: 4;
    background-color: $fill-bg-content;
  }

  .content {
    display: flex;
    height: 92vh;

    .left-side {
      transition: flex 0.2s linear 0.1s;
      // transform: translateX(0);
      flex: 1.5;
      width: 100%;
      &.collapse {
        flex: 0;
        width: 0;
      }
      ul {
        margin-top: 30px;
        li {
          display: flex;
          align-items: center;
          border-left: 3px solid $fill-dark-grey;
          padding: 10px 10px 10px 40px;
          color: $fill-white;
          img {
            margin-right: 20px;
            max-width: 20px;
          }
          &.react-tabs__tab--selected {
            background-color: #3a3b3c;
            border-left: 3px solid $fill-blue-header;
          }
        }
      }
    }

    .right-side {
      transition: flex 0.2s linear 0.1s;
      flex: 4 1;
      &.expand {
        flex: 1;
        width: 100%;
      }
      padding: 20px;
      overflow: auto;
    }
  }

  .report-content {
    .header-content {
      span {
        font-size: 1.4rem;
      }
      .left-content {
        width: 30%;
      }
      .right-content {
        text-align: right;
        width: 70%;
        .input-date {
          text-align: center;
          width: 50%;
          font-family: "db_heaventmed";
          font-size: 1.4rem;
        }
      }
    }
  }

  .statistic-content {
    .header-content {
      span {
        font-size: 1.4rem;
      }
      .left-content {
        width: 30%;
      }
      .right-content {
        text-align: right;
        width: 60%;
      }
    }
    .control-data {
      
      .input-checkbox-stats {
        width: 78%;
        display: inline-block;
      }

      .btn-xls {
        width: 20%;
        margin: 0 1%;
        display: inline-block;
      }

      .input-select-stats {
        width: 78%;
        display: inline-block;
      }
      .btn-round {
        width: 18%;
        margin: 0 1%;
        max-width: 200px;
        padding: 10px 20px;
      }
    }
  }

  .react-tabs {
    width: 100%;
    display: flex;
  }

  .shop-content,
  .report-content,
  .statistic-content {
    position: relative;
  }

  .header-content {
    margin-top: 10px;
    display: flex;

    .left-content {
      // flex: 1;
      width: 50%;
      h1 {
        font-size: 2.4rem;
      }
      p {
        font-size: 1.4rem;
        color: $fill-grey-font;
        word-wrap: break-word;
      }
    }
    .center-content {
      width: 10%;
      display: flex;
      justify-content: center;
      // align-items: start;
      padding-top: 12px;
    }
    .right-content {
      // flex: 1;
      width: 40%;
    }

    .btn-reload {
      width: 24px;
      height: 24px;
    }
  }

  .btn-xls,
  .btn-calendar {
    width: 24px;
    height: 24px;
  }

  .btn-xls {
    width: 20%;
    text-align: center;
    img {
      display: inline-block;
      vertical-align: top;
    }
    span {
      margin-left: 6px;
      display: inline-block;
      font-size: 1rem;
    }
  }

  .input-search {
    width: 40%;
    top: 0;
    right: 0;
    position: absolute;
    margin: auto;
    background: #fff url(./assets/img/ic-search.svg) no-repeat scroll 12px;
    padding-left: 38px;
    font-family: "db_heaventmed";
    font-size: 1.4rem;
  }

  .input-search-report {
    width: 70%;
    background: #fff url(./assets/img/ic-search.svg) no-repeat scroll 12px;
    padding-left: 38px;
    font-family: "db_heaventmed";
    font-size: 1.4rem;
    margin-bottom: 15px;
  }

  .input-filter-status {
    // width: 40%;
    border: none;
    background-color: #fff;
    padding: 6px 4px;
    font-family: "db_heaventmed";
    font-size: 1.2rem;
    border-radius: 4px;
    margin-bottom: 10px;
    text-align: center;
  }
}

// Shop -----------------------------------------------------------//
.btn-tableRowSelect {
  text-align: right;
  margin-top: 30px;
  button {
    padding: 18px 40px;
  }
}

// Date Picker Range ----------------------------------------------//
.rdrWeekDay {
  font-size: 1rem;
}
button {
  &.rdrDay {
    margin: 0;
    font-size: 1rem;
  }
  // .rdrEndEdge,
  // .rdrStartEdge {
  //   border-top-right-radius: 1.15rem;
  //   border-bottom-right-radius: 1.15em;
  // }
}

// Modal ----------------------------------------------------------//
._modal {
  width: 90%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: 25% auto;
  background-color: $fill-white;
  width: 50%;
  border-radius: 10px;
  // transform: translate(0, 15%);

  &.modal-date {
    width: auto;
    max-width: 360px;
    padding: 10px;
    text-align: center;
    transform: translateY(-25%);
    ._date-modal {
      .rdrDateInput {
        input {
          border: none;
          margin: 0;
          padding: 0;
          font-size: 1rem;
          color: $fill-black;
        }
      }
    }
  }

  ._error-modal,
  ._insertcode-modal {
    text-align: center;
    padding: 20px;

    .rdrDateInput {
      input {
        border: none;
        margin: 0;
        padding: 0;
        font-size: 1.4rem;
        color: $fill-black;
      }
    }

    p {
      color: $fill-black;
      font-size: 1.6rem;
      &.font-red {
        font-size: 1.2rem;
        color: $fill-red;
        text-align: left;
      }
    }

    input {
      border: 1px solid $fill-blue;
      color: $fill-blue;
      font-family: "db_heaventmed";
      border-radius: 10px 10px 10px 10px;
      font-size: 2rem;
      padding: 6px 6px;
      margin: 10px 0 20px;
      width: 100%;
    }

    button {
      p {
        color: $fill-white;
      }
    }
  }

  ._result {
    text-align: center;
    padding: 20px;
    font-size: 2rem;

    > div {
      margin: 10px 0 12px;
    }

    img {
      vertical-align: bottom;
      margin-right: 10px;
    }

    span,
    p {
      font-size: 1.6rem;
      margin-right: 8px;
      line-height: 1;

      &:last-child {
        font-size: 2rem;
        margin: 0;
      }

      &.font-blue {
        font-size: 2.6rem;
      }
    }

    button {
      margin-top: 18px;
      width: 180px;

      .btn-ellipse {
        p {
          color: $fill-white;
          font-size: 1.6rem;
        }
      }
    }
  }
}

// Responsive ----------------------//
@media (max-width: 1023px) {
  .login-page {
    .login-box {
      width: 72%;
    }
  }
}


// React Select ----------------------//
// div[id*='react-select'][id$='heading'] {
//   color: black;
//   font-size: 1.6rem;
// }

// React Select - item in listmenu
div[class*='css'][class$='option'] {
  // color: gray;
  // text-indent: 40px;
  // font-size: 1.2rem;
  &:hover, &:visited {
    background-color: #fff;
  }
}

// div[class*='css-9gakcf'][class$='option'] {
//   color: white;
//   text-indent: 40px;
//   font-size: 1.2rem;
// }

.select-header {
  &.select-all {
    background-image: url(./assets/img/ic-doublecheck.svg);
    background-repeat: no-repeat;
    background-size: 20px;
    background-position: 5px center;
  }
}

// Checkbox Circle ----------------------//
.input-checkbox-stats {
  .container {
    display: inline-block;
    margin-right: 20px;
  }
}

/* The container */
.container {
  display: block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 22px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* Hide the browser's default checkbox */
.container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom checkbox */
.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 25px;
  width: 25px;
  background-color: #ccc;
  border-radius: 100px;
}

/* On mouse-over, add a grey background color */
.container:hover input ~ .checkmark {
  background-color: #ccc;
}

/* When the checkbox is checked, add a blue background */
.container input:checked ~ .checkmark {
  background-color: #2196F3;
  outline: none;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
.container input:checked ~ .checkmark:after {
  display: block;
}

/* Style the checkmark/indicator */
.container .checkmark:after {
  left: 9px;
  top: 5px;
  width: 5px;
  height: 10px;
  border: solid white;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

// Checkbox switch ----------------------//
/* The switch - the box around the slider */
.switch {
  position: relative;
  display: inline-block;
  width: 50px;
  height: 24px;
}

/* Hide default HTML checkbox */
.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

/* The slider */
.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: .4s;
  transition: .4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 16px;
  width: 16px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: .4s;
  transition: .4s;
}

input:checked + .slider {
  background-color: #2196F3;
}

input:focus + .slider {
  box-shadow: 0 0 1px #2196F3;
}

input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}

// HighChart ----------------------------//
.chart-area {
  background-color: #fff;
  
  .chart-btn-switch {
    display: flex;
    align-items: center;

    .switch {
      margin: 10px;
    }
    span {
      color: #76838f;
      font-size: 1.4rem;
    }
  }

  .chart-container {
    padding: 0 20px;
  }

  .chart-row {
    display: flex;
    align-items: center;
    padding: 10px 20px;
    justify-content: space-between;

    .chart-row-left {
      width: 30%;
    }
    .chart-row-right {
      width: 70%;
      display: flex;
      align-items: center;
      justify-content: flex-end;
    }
  }

  .chart-btn-drill {
    width: 30px;
    height: 30px;
    padding: 5px;
    margin: 0;
    background-color: #ccc;
    border-radius: 4px;
    img {
      width: 100%;
    }
  }
}

.chart-overlay {
  position: absolute;
  width: 100%;
  height: 100%;
  background: rgba(241, 245, 246,0.5);
  z-index: 9;
  display: flex;
  align-items: center;
  justify-content: center;
}

// Stats page -----------------//
.statistic-content {
  .btn-xls {
    margin: 0 0 0 20px;
    
    &.for-stats {    
      width: auto;
      display: flex;
      align-items: center;
      padding: 10px;
      height: auto;
      background-color: #dedede;
      border-radius: 4px;
      img {
        width: 20px;
        height: 20px;
      }
    }
  }

  .table-area {
    border-top: 1px solid #ccc;
  }
  .table-export {
    display: flex;
    justify-content: flex-end;
    padding: 10px 0;
  }
}
